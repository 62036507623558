.container-tabs {
    width: fit-content;
    
    .tabs {
        display: flex;
        background-color: #fff;
        padding: 0.375rem;
        border-radius: 10px; // just a high number to create pill effect
        * {
            z-index: 2;
        }
    
        input[type="radio"] {
            display: none;
    
            &:checked {
                & + label {
                    color: $color-dark;
                    & > .notification {
                        background-color: $color-dark;
                        color: #fff;
                    }
                }
            }
        }
    
        .tab {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            width: 84px;
            font-size: 1rem;
            font-weight: 500;
            border-radius: 4px; // just a high number to create pill effect
            cursor: pointer;
            transition: color 0.15s ease-in;
        }
    
        .glider {
            position: absolute;
            display: flex;
            height: 35px;
            width: 84px;
            background-color: $color-primary;
            z-index: 1 !important;
            border-radius: 4px; // just a high number to create pill effect
            transition: 0.25s ease-out;
        }
        
        input[id="all"] {
            &:checked {
                & ~ .glider {
                    transform: translateX(0);
                }
            }
        }
        
        input[id="enabled"] {
            &:checked {
                & ~ .glider {
                    transform: translateX(100%);
                }
            }
        }
        
        input[id="disabled"] {
            &:checked {
                & ~ .glider {
                    transform: translateX(200%);
                }
            }
        }
        
        input[id="tournament"] {
            &:checked {
                & ~ .glider {
                    transform: translateX(300%);
                }
            }
        }
        
        input[id="schedule"] {
            &:checked {
                & ~ .glider {
                    transform: translateX(300%);
                }
            }
        }
        
        input[id="assets"] {
            &:checked {
                & ~ .glider {
                    transform: translateX(0);
                }
            }
        }
        
        input[id="stock"] {
            &:checked {
                & ~ .glider {
                    transform: translateX(100%);
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .container-tabs .tabs {
        .tab, .glider {
            height: 25px;
            width: 64px;
            font-size: 0.8rem;
            img {
                display: none;
                margin-right: 0;
            }
        }
    }
}