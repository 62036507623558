$color-primary: #00FC84;
$color-primary-medium: #00C768;
$color-danger: #FD4949;
$color-warning: #FEBF02;
$color-warning-medium: #ff8d00;
$color-success: #00C768;
$color-over: #4C5E64;
$color-over-light: #cecece;
$color-medium: #9b9b9b;
$color-light: #FFFFFF;
$color-dark: #081E26;
$color-extra-dark: #000000;
$color-link: #009cbf;
$color-info: #004972;
$color-card-info: #93D8FF;

$color-background: #F5F5F5;
$color-cards: $color-light;
$color-value-cards:$color-background;
$color-cards-dark: $color-extra-dark;

$input-background: #F5F5F5;
$input-background-dark: #36373B;

$border-radius: 10px;
$border-radius-small-components: 8px;

$font-normal: 14px;
$font-card-title: 18px;
$font-page-title: 26px;
$font-social-title: 20px;

$button-max-width: 250px;
$button-max-width-mobile: 100%;

$input-max-width: 416px;
$input-max-width-mobile: 100%;

$plan-stock-background: #052026;
$plan-stock-over: #C4C4C4;