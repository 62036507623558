@import './variables.scss';

.alert {
    background-color: rgba($color: $color-warning, $alpha: 0.5);
    padding: 16px;
    margin-bottom: 32px;
    border-radius: $border-radius;
    border: 2px solid $color-warning;

    .alert-title {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 16px;
    }
}