@import "./variables.scss";

.header {
    position: fixed;
    height: 95px;
    display: flex;
    align-items: center;
    background-color: $color-dark;
    width: 100vw;
    left: 0px;
    top: 0px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 10;

    .logo {
        width: 150px;
        margin-left: 60px;
        cursor: pointer;
    }

    .header-mobile {
        display: none !important;
    }

    .items-header {
        transition: all 0.3s;
        margin-left: 160px;
        display: flex;
        color: $color-light;

        &.retract {
            margin-left: 276px;
        }

        .new-account {
            width: 220px;
            font-weight: 700;
        }

        .item {
            display: flex;
            align-items: center;
            margin-right: 48px;
            font-size: 16px;
            transition: all 0.3s linear;
            cursor: pointer;
        }

        .item-user {
            padding-block: 8px;

            .img-profile {
                width: 60px;
                height: 60px;
                border-radius: 100%;
                background: $color-primary;
                margin-right: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: $color-dark;
                font-weight: 500;
                font-size: 24px;
                overflow: hidden;

                img {
                    width: 100%;
                }
            }
            
            .welcome {
                margin-right: 14px;
                span {
                    display: block;
                }
                .name {
                    font-weight: 700;
                }
            }
            
            .menu-button {
                color: $plan-stock-over;

                i {
                    margin-right: 16px;
                    font-size: 16px;
                }
            }
        }

        .item-support {
            margin-right: 16px;
        }

        .item:hover {
            color: $color-primary-medium;

            .menu-button {
                color: $color-primary-medium;
            }
        }
    }
    .logout-web {
        position: absolute;
        right: 0;
    }
}

@media(max-width: 1250px) {
    .header {
        .header-web:not(.logout-web) {
            .item {
                margin-right: 24px;

                i, img {
                    margin-right: 8px;
                }
            }
        }
    }
}

@media (max-width: 850px) {
    .header {
        height: 75px;
        justify-content: space-between;
        .logo {
            margin-left: 32px;
        }

        .header-mobile {
            display: flex !important;

            img {
                width: 150px;
            }
        }
        .header-web {
            display: none !important;
        }

        .items-header {
            margin-left: 0;
    
            .item {
                margin-right: 16px;
            }

            .item:hover {
                color: $color-over;
            }
        }
    }
}