@import "./variables.scss";

.input {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    max-width: $input-max-width;
    width: 100%;

    .input-box {
        border-radius: $border-radius-small-components;
        height: 46px;
        border: none;
        background-color: $input-background;
        transition: box-shadow 0.3s ease-in-out;
        color: $color-dark;
        padding-left: 16px;
        font-weight: 400;
        font-size: $font-normal;
    }

    .input-box::placeholder {
        color: $color-over;
        font-size: $font-normal;
    }

    .input-box:focus {
        border: 3px solid rgba($color: $color-primary, $alpha: 0.43);
        outline: none;
        height: 40px;
        padding-left: 13px;
    }

    .input-box-error {
        border: 3px solid rgba($color: $color-danger, $alpha: 0.43) !important;
        outline: none !important;
        height: 42px;
    }
}

.input-dark {
    .input-box {
        background-color: $input-background-dark;
        color: $color-light;
    }
    .input-box::placeholder {
        color: $color-light;
        font-size: $font-normal;
    }
}

@media (max-width: 750px) {
    .input {
        max-width: $input-max-width-mobile;
    }
}