@import "variables.scss";

mat-checkbox {
    .mdc-label {
        color: $color-over;
    }
    
    .mdc-checkbox {
        padding: 0;
        margin: 0;
        margin-right: 6px;

        .mdc-checkbox__background {
            top: 0;
            left: 0;
        }
    }

    .mat-mdc-checkbox-touch-target {
        width: 20px;
        height: 20px;
    }

    --mdc-checkbox-disabled-selected-icon-color: #578b89;
    --mdc-checkbox-disabled-unselected-icon-color: #a5a7ad;

    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-icon-color: #{$color-primary} !important;
    --mdc-checkbox-selected-hover-icon-color: #{$color-primary} !important;
    --mdc-checkbox-selected-icon-color: #{$color-primary} !important;
    --mdc-checkbox-selected-pressed-icon-color: #{$color-primary} !important;
    
    --mdc-checkbox-unselected-focus-icon-color: #a5a7ad;
    --mdc-checkbox-unselected-hover-icon-color: #a5a7ad;
    --mdc-checkbox-unselected-icon-color: #a5a7ad;
    --mdc-checkbox-unselected-pressed-icon-color: #a5a7ad;
    
    --mdc-checkbox-selected-focus-state-layer-color: #{$color-primary} !important;
    --mdc-checkbox-selected-hover-state-layer-color: #{$color-primary} !important;
    --mdc-checkbox-selected-pressed-state-layer-color: #{$color-primary} !important;
    --mdc-checkbox-unselected-focus-state-layer-color: #a5a7ad;
    --mdc-checkbox-unselected-hover-state-layer-color: #a5a7ad;
    --mdc-checkbox-unselected-pressed-state-layer-color: #a5a7ad;

    --mdc-checkbox-state-layer-size: 20px;

    &.error-form {
        .mdc-label {
            color: $color-danger !important;
        }

        --mdc-checkbox-unselected-icon-color: #fe2a46;
    }
}