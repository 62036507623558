@import "./variables.scss";

.card {
    padding: 24px;
    background-color: $color-cards;
    border-radius: $border-radius;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    .card-title {
        font-size: $font-card-title;
        font-weight: 600;
        margin-bottom: 32px;
        width: 100%;
        align-items: start;
    }
}