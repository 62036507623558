/*  Borda redonda editor    */
.cke, .cke_inner {
    border: none !important;
}

.cke_top{
    border: none !important;
} .cke_bottom {
    border: none !important;
}

/*  Cor da barra superior e inferior    */
.cke_top, .cke_bottom{
    background: #1D2331 !important;
}

/*  Cor da borda do editor    */
.cke_chrome, .cke_top, .cke_bottom {
    border: none !important;
}

/*  Inverte a cor dos botões    */
.cke_button_icon {
    filter: contrast(2) invert(100%) !important;
}

/*  Cor do texto na barra inferior    */
a.cke_path_item, .cke_combo_text {
    color: white !important;
}

/*  Cor da sobreposição do botão na toolbar    */
a.cke_button_off:hover, a.cke_button_off:focus, a.cke_button_off:active {
    background: #2e2e2e !important;
}

/*  Cor da sobreposição do botão na toolbar    */
.cke_combo_on a.cke_combo_button, .cke_combo_off a.cke_combo_button:hover, .cke_combo_off a.cke_combo_button:focus, .cke_combo_off a.cke_combo_button:active, a.cke_button_on {
    background: #2e2e2e !important;
}

.cke_editable {
    background-color: #131821 !important;
    color: white !important;
    font-family: "Roboto";
    font-size: 14px;
}