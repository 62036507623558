@import "./variables.scss";

.mdc-menu-surface {
    --mdc-shape-medium: 10px;
}

.mat-mdc-menu-content {
    padding: 16px 14px 10px 16px !important;

    .menu-title {
        font-size: 16px;
        font-weight: 700;
    }

    .user {
        margin-block: 20px;
        color: $color-medium;

        .name {
            display: block;
            margin-bottom: 8px;
        }
    }

    .mat-mdc-menu-item {
        min-height: unset;
        font-weight: 700;
        font-size: 12px;
        min-width: 250px;

        i {
            font-size: 18px;
        }
    }

    .button-primary {
        margin-bottom: 14px;
        background-color: $color-primary;
    }

    .mdc-list-item__primary-text {
        display: flex;
        align-items: center;
    }
}