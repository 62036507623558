@import "./variables.scss";

button {
    width: 100%;
    max-width: $button-max-width;
    border-radius: $border-radius-small-components;
    height: 40px;
    cursor: pointer;
    border: none;
    transition: all linear 0.2s;
    
    i {
        margin-right: 16px;
    }

    &:disabled {
        filter: grayscale(0.8) opacity(0.5);
    }
}

button.card {
    padding: 8px 12px;
    height: auto;
    width: auto;
}

.button-primary {
    background-color: $color-primary;
    color: $color-dark;
    transition: all 0.3s;
}

.button-primary:hover {
    background-color: $color-primary-medium;
    color: $color-dark;
}

.button-danger {
    background-color: $color-danger;
    color: $color-light;
}

.button-dark {
    background-color: $color-dark;
    color: $color-light;
}

.button-extra-dark {
    background-color: $color-extra-dark;
    color: $color-light;
}

.button-outline {
    background-color: transparent;
    color: $color-dark;
    border: solid 1px $color-dark;
    transition: all 0.3s;
}

.button-outline:hover {
    background-color: $color-light;
}

.button-secondary {
    background-color: $color-dark;
    color: $color-primary;
}

.button-secondary:hover {
    background-color: rgba($color: $color-dark, $alpha: 0.9);
}

.button-transparent {
    background-color: transparent;
    color: $color-dark;
    border: solid 1px transparent;
}

@media (max-width: 750px) {
    button {
        max-width: $button-max-width-mobile;
    }
}