@import "./variables.scss";
.select {
    mat-form-field {
        width: 100%;

        &.input-box-error {
            border: 3px solid rgba($color: $color-danger, $alpha: 0.43) !important;
            outline: none;
            border-radius: 8px;
        }

        .mdc-text-field--focused {
            border: 3px solid rgba($color: $color-primary, $alpha: 0.43);
            outline: none;
        }

        .mat-mdc-text-field-wrapper, .mat-mdc-form-field-flex {
            height: 46px;
        }

        .mat-mdc-form-field-infix {
            min-height: 46px !important;
        }

        .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
            padding-top: 12px;
            padding-inline: 16px;
        }

        .mdc-text-field--filled {
            --mdc-filled-text-field-container-shape: 8px;
            border-radius: var(--mdc-filled-text-field-container-shape);
            padding: 0;
        }

        .mat-mdc-form-field-subscript-wrapper {
            height: 0;
        }

    }
    
}

.account {
    .mat-mdc-text-field-wrapper, .mat-mdc-form-field-flex {
        background: #eaeaea;
    }
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
    color: $color-primary-medium;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before, 
.mdc-text-field--filled:not(.mdc-text-field--disabled):hover .mdc-line-ripple::before, 
.mdc-text-field--filled .mdc-line-ripple::after {
    border-bottom-color: transparent !important;
}
.mat-mdc-form-field.mat-focused .mat-mdc-text-field-wrapper.mdc-text-field--focused .mat-mdc-form-field-flex {
    align-items: center;
}