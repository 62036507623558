
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mesa-prime-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$mesa-prime-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$mesa-prime-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$mesa-prime-theme: mat.m2-define-light-theme((
  color: (
    primary: $mesa-prime-primary,
    accent: $mesa-prime-accent,
    warn: $mesa-prime-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($mesa-prime-theme);

/* You can add global styles to this file, and also import other style files */

@import "assets/styles/variables.scss";
@import "assets/styles/input.scss";
@import "assets/styles/select.scss";
@import "assets/styles/fonts.scss";
@import "assets/styles/button.scss";
@import "assets/styles/header.scss";
@import "assets/styles/card.scss";
@import "assets/styles/modal.scss";
@import "assets/styles/ckeditor.scss";
@import "assets/styles/grid.scss";
@import "assets/styles/alert.scss";
@import "assets/styles/checkbox.scss";
@import "assets/styles/radio.scss";
@import "assets/styles/popover-menu.scss";
@import "assets/styles/sidenav-web.scss";
@import "assets/styles/tabs.scss";
@import "assets/styles/input-calendar.scss";

body {
    font-size: 14px;
    background-color: $color-background;
    color: $color-dark;
    font-family: Roboto;
}

.content {
    margin-inline: auto;
    padding-block: 48px;
    position: relative;
    box-sizing: border-box;
    top: 95px;
    left: 0px;
    width: 100%;
    max-width: 1180px;

    .title {
        font-size: $font-page-title;
        margin-bottom: 48px;
    }
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mat-content.mat-content-hide-toggle {
    margin-right: 0px !important;
}

@media (max-width: 1366px) {
  .content {
    padding-inline: 60px;
  }
}

@media (max-width: 850px) {
  .content {
    top: 75px;
  }
}

@media (max-width: 750px) {
  .content {
    padding: 32px;
    height: unset !important;
  }
}