@import "variables.scss";


@font-face {
    font-family: "Roboto";
    font-weight: 400;
    src: url("/assets/fonts/Roboto-Light.ttf");
}

@font-face {
    font-family: "Roboto";
    font-weight: 100;
    src: url("/assets/fonts/Roboto-Thin.ttf");
}


@font-face {
    font-family: "Roboto";
    font-weight: 500;
    src: url("/assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: "Roboto";
    font-weight: 700;
    src: url("/assets/fonts/Roboto-Medium.ttf");
}

.color-primary {
    color: $color-primary !important;
}

.color-primary-medium {
    color: $color-primary-medium !important;
}

.color-danger {
    color: $color-danger !important;
}

.color-warning {
    color: $color-warning !important;
}

.color-warning-medium {
    color: $color-warning-medium !important;
}

.color-over {
    color: $color-over !important;
}

.color-medium {
    color: $color-medium !important;
}

.color-info {
    color: $color-link !important;
}

.color-highlight {
    color: $color-dark;
    font-weight: 700;
}

.color-dark {
    color: $color-dark !important;
}

.text-click {
    color: $color-link;
    text-decoration: underline;
}

.error-form {
    color: $color-danger;
    font-weight: 300;
    font-size: 12px;
    position: relative;
}

.over-text {
    color: #4C5E64;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}